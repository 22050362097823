html {
  scroll-behavior: smooth;
}

.App {
  /* text-align: center; */
  background-color: #282c34;
  min-height: 100vh;

}
.ball-juggler {
    margin-top: 15rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.words-of-wisdom {
  display: flex;
  padding-top: 15rem;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: 0vmin;
  justify-content: center;
  color: #595f6b;
}



.App-logo {
  width: 8rem;
  height: 8rem;
  border-radius: 150px;

}

#c1 {
  offset-path: path("M 0 0 L 0 80");

  transform-origin: bottom;
  animation: move 3000ms infinite alternate ease-in-out;
}

#c2 {
 animation: move 3000ms infinite alternate ease-in-out;
  animation-delay: 200ms;
  transform-origin: bottom;
  offset-path: path("M 0 0 L 0 80");

}
#c3 {
  transform-origin: bottom;
  offset-path: path("M 0 0 L 0 80");
  animation: move 3000ms infinite alternate ease-in-out;
  animation-delay: 300ms;

} 
@keyframes move {
  0% {
    offset-distance: 0%;
    
    background-color: rgb(255, 77, 77);
  }
  25% {
    offset-distance: 100%;
    background-color: rgb(202, 112, 8);
  }
  50% {
    offset-distance: 0%;
    background-color: rgb(255, 239, 8);
  
  }
  75% {
    offset-distance: 100%;
    background-color: rgb(78, 173, 0);

  }
  100% {
    offset-distance: 0%;
    background-color: rgb(180, 0, 180);

  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {


}

.App-link {
  color: #61dafb;
}

.middle {
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 7.5vw;
  margin-inline: auto; 
  width: fit-content;
  background-color: #61dafb !important;
}

.middle * {
  position: relative;
}

.tabPanel {

  background-color: rgba(70, 70, 70, 0.11);
}

.tabPanel p {
  font-size: 3rem;
  font-weight: 800;
}

.tabPanel:nth-child(1) {
  color: red;
  border: red 5px solid
}

.tabPanel:nth-child(2) {
  color: orange;
  border: orange 5px solid

}

.tabPanel:nth-child(3) {
  color: yellow;
  border: yellow 5px solid

}

.tabPanel:nth-child(4) {
  color: green;
  border: green 5px solid

}

.tabPanel:nth-child(5) {
  color: blue;
  border: blue 5px solid

}

.tabPanel:nth-child(6) {
  color: indigo;
  border: indigo 5px solid

}

.tabPanel:nth-child(7) {
  color: violet;
  border: violet 5px solid

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
